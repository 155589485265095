import React from "react";
import logoImg from "../Assets/logo.png";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <img src={logoImg} alt="Be-Perfect" className="about-image1" />
      </div>

      <div className="about-text-content  font-section">
        <h3 className="about-title font-section">
          <span>من نحن</span>
        </h3>
        <p className="about-description  font-section">
          مرحبا بك في عيادة Be Perfect مع الدكتور محمد سعيد دنيا استشاري جراحات التجميل وتنسيق القوام
        </p>
        <p className="about-description address  font-section">
        العنوان :</p>
        <p className="about-description  font-section">
        📍 القاهرة مصر الجديدة- 7 عمارات الشركة السعودية- شارع النزهة- أمام دار الدفاع الجوى
        </p>
      </div>
    </div>
  );
}

export default About;
