import React, { useEffect } from "react";
import "../Styles/Slider.css";
import $ from "jquery";
import Image1 from "../Assets/article-1.jpg";
import Image2 from "../Assets/article-2.jpg";
import Image3 from "../Assets/article-3.jpg";

const Slider = () => {
  useEffect(() => {
    $(document).ready(function () {
      // تأكد من تشغيل السكربت بعد تحميل جميع العناصر
      $(".button-next").click(function () {
        var e = $(".feature-slide.active");
        $(e).removeClass("active");
        $(e).next().addClass("active");
        $(".feature-slide").hasClass("active") ||
          $(".feature-slide:first").addClass("active");
      });

      $(".button-prev").click(function () {
        var e = $(".feature-slide.active");
        $(e).removeClass("active");
        $(e).prev().addClass("active");
        $(".feature-slide").hasClass("active") ||
          $(".feature-slide:last").addClass("active");
      });
    });
  }, []);

  return (
    <div className="body">
      <div className="wrapper">
        <div className="row row-features">
          <div className="block-wrap h50 mob-auto">
            <div className="block">
              <div className="new-feature-slider">
                <div id="slider-1" className="feature-slide active">
                  <div className="block-wrap w50 new-feature-image-wrap mob-auto">
                    <div className="block">
                      <div className="feature-slide-image">
                        <div
                          className=""
                          style={{ backgroundImage: "url({Image1})" }}
                        >
                          <img src={Image1} alt="Slider 1" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="block-wrap w50 center-content mob-auto">
                    <div className="block">
                      <div className="content-centered overview-text">
                        <h2 className="fakeh1">
                          بتدورى علي طریقة فعالة تخلصك من تجاعید الجبهه وتستمتعى
                          بمظھر شبابى مشدود 🤔 تقدرى تقولى ان البوتكس هو الحل ال
                          بتدورى عليه لتحقيق إطلاله كلها شباب وحيوية 🤩✨
                          لتفاصيل ومعلومات أكتر ابعتولنا على رسائل الصفحة واحجزى
                          أقرب ميعاد ليكى🥰
                        </h2>
                        <span className="divider-wave blue-wave"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="slider-2" className="feature-slide">
                  <div className="block-wrap w50 new-feature-image-wrap mob-auto">
                    <div className="block">
                      <div className="feature-slide-image">
                        <div
                          className=""
                          style={{ backgroundImage: "url({Image2})" }}
                        >
                          <img src={Image2} alt="Slider 2" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="block-wrap w50 center-content mob-auto">
                    <div className="block">
                      <div className="content-centered overview-text">
                        <h2 className="fakeh1">
                          تألقى بجاذبيتك وثقتك بنفسك بدون دهون زائدة! 💋مع
                          عمليات تجميل البطن، يمكنك تحقيق جسم مشدود وإطلالة
                          رائعة من غير الحاجة للتعب من الدهون😘 استعيدى ثقتك
                          بنفسك وسيبى جاذبيتك تتألق بلا حدود مع د/محمد دنيا😍
                          لتفاصيل ومعلومات أكتر ابعتولنا على رسائل الصفحة واحجزى
                          أقرب ميعاد ليكى🥰
                        </h2>
                        <span className="divider-wave blue-wave"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="slider-3" className="feature-slide">
                  <div className="block-wrap w50 new-feature-image-wrap mob-auto">
                    <div className="block">
                      <div className="feature-slide-image">
                        <div
                          className=""
                          style={{ backgroundImage: "url({Image3})" }}
                        >
                          <img src={Image3} alt="Slider 3" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="block-wrap w50 center-content mob-auto">
                    <div className="block">
                      <div className="content-centered overview-text">
                        <h2 className="fakeh1">
                          ل تبحثين عن طريقة لتحسين شكل ثديك ورفعه بشكل طبيعى؟🙄
                          شوفى الحلّ! 😘رفع الثدى مش مجرد حلم بعيد بل يمكن
                          تحقيقه🤩 مع دكتور محمد دنيا يمكنك الحصول على ثدى مشدود
                          وجذاب بأمان وثقة👌❤ لتفاصيل ومعلومات أكتر ابعتولنا
                          على رسائل الصفحة واحجزى أقرب ميعاد ليكى🥰
                        </h2>
                        <span className="divider-wave blue-wave"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="controls new-feature-controls">
                <span className="control button-prev"></span>
                <span className="control button-next"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
