import React from "react";
import Navbar from "../Components/Navbar";
import Hero from "../Components/Hero";
import Info from "../Components/Info";
import Slider from "../Components/Slider";
import About from "../Components/About";
import BookAppointment from "../Components/BookAppointment";
import Map from "../Components/Map";
import Footer from "../Components/Footer";

function Home() {
  return (
    <div className="home-section">
      <Navbar />
      <Hero />
      <Info />
      <Slider />
      <BookAppointment />
      <About />
      <Map />
      <Footer />
    </div>
  );
}

export default Home;
