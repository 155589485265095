import React from "react";
import "../Styles/Info.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function Info() {
  const navigate = useNavigate();
  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };

  return (
    <div className="info-section font-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>نبذه مختصرة</span>
        </h3>
        <p className="info-description">
          نقدم الرعاية الصحية إلى متناول يديك، ونقدم مجموعة شاملة من الخدمات الطبية حسب الطلب مصممة وفقًا لاحتياجاتك. تتيح لك منصتنا الاتصال بالمساعدين المتخصصين عبر الإنترنت يقدمون النصائح الطبية الخبيرة عبر الإنترنت، ويقدمون التعبئة السريعة كلما احتجت إليها.
        </p>
        <button
            className="text-appointment-btn"
            type="button"
            onClick={handleBookAppointmentClick}
        >
            <FontAwesomeIcon icon={faCalendarCheck} /> سجل معنا الأن
        </button>
      </div>
    </div>
  );
}

export default Info;
