import React from "react";
import "../Styles/NotFound.css";

function NotFound() {
  return (
    <div className="notfound">
      <h1 className="notfound-title">404</h1>
      <p className="notfound-description">الصفحة غير موجودة يرجى التأكد من الرابط</p>
      <a className="notfound-button" href="/">
        <i className="icon-home"></i>الرجوع الى الصفحة السابقة
      </a>
    </div>
  );
}

export default NotFound;
