import React from "react";
import "../Styles/Footer.css";
import logoImg from "../Assets/logo.png";
import SubscribeNewsletter from "./SubscribeNewsletter";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <p className="ft-title">
              <a className="logo" href="/Health-Plus">
                <img src={logoImg} alt="be perfect" />
              </a>
            </p>
            <p className="ft-description font-section">
              تحدث إلينا الآن عبر الإنترنت واحصل على المشورة الطبية والوصفات
              الطبية عبر الإنترنت، الغيارات والملاحظات الطبية في غضون دقائق.
              الرعاية الصحية عند الطلب الخدمات في متناول الجميع.
            </p>
          </div>

          <SubscribeNewsletter />
        </div>
        <div className="ft-list font-section">
          <p className="ft-list-title font-section">روابط مهمة</p>
          <ul className="ft-list-items">
            <li>
              <Link to={"/legal"}>معلومات عامة</Link>
            </li>
            <li>
              <Link to={"/legal"}>سياسة الخصوصية</Link>
            </li>
            <li>
              <Link to={"/legal"}>شروط الخدمة</Link>
            </li>
            <li>
              <Link to={"/legal"}>كيفية العمل</Link>
            </li>
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title font-section">تواصل معنا</p>
          <ul className="ft-list-items font-section">
            <li>
              <a href="mailto:support@drmohameddonia.com">support@drmohameddonia.com</a>
            </li>
            <li>
              <a href="tel:+201090057910">01090057910</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="ft-copyright font-section">
        <p>© 2024 BE PERFECT جميع الحقوق محفوظة</p>

        <ul className="ft-social-links">
          <li>
            <a
              href="https://www.tiktok.com/@dr_mohameddonia?_t=8jofHgcXH7u&_r=1"
              title="TikTok"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg 
                viewBox="0 0 24 24" 
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m12.53.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"/>
              </svg>
            </a>
          </li>

          <li>
            <a
              href="https://www.facebook.com/beperfectclinic?mibextid=2JQ9oc"
              title="FaceBook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 320 512"
              >
                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
              </svg>
            </a>
          </li>

          <li>
            <a
              href="https://www.instagram.com/dr_mohameddonia?igsh=ZGpzZ2xxYzViYzZi"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg 
                viewBox="0 0 24 24" 
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path 
                  d="m12 0c-3.26 0-3.667.015-4.947.072-1.278.06-2.148.261-2.913.558-.789.306-1.459.717-2.126 1.384s-1.079 1.336-1.384 2.126c-.297.765-.499 1.635-.558 2.913-.06 1.28-.072 1.687-.072 4.947s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558 1.28.06 1.687.072 4.947.072s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126-.667-.667-1.335-1.079-2.126-1.384-.765-.297-1.636-.499-2.913-.558-1.28-.06-1.687-.072-4.947-.072zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zm0 10.162c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44s.646-1.439 1.44-1.439c.793-.001 1.44.645 1.44 1.439z"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
