import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoImg from "../Assets/logo.png";
import {
  faCommentDots,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Navbar.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function Navbar() {
  const [nav, setNav] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const openNav = () => {
    setNav(!nav);
  };

  const handleChatBtnClick = () => {
    if (!isButtonDisabled) {
      toast.info("سيتم تحويلك على المدونة الأن....", {
        position: toast.POSITION.TOP_CENTER,
        onOpen: () => setIsButtonDisabled(true),
        onClose: () => setIsButtonDisabled(false),
      });
    }
  };

  return (
    <div className="navbar-section">
      <h1 className="navbar-title">
        <a className="logo" href="/Be-Perfect">
          <img src={logoImg} alt="be perfect" />
        </a>
      </h1>

      {/* Desktop */}
      <ul className="navbar-items">
        <li>
          <Link to="/" className="navbar-links font-section">
            الصفحة الرئيسية
          </Link>
        </li>
        <li>
          <a href="#services" className="navbar-links">
            الخدمات
          </a>
        </li>
        <li>
          <a href="#about" className="navbar-links">
            من نحن
          </a>
        </li>
      </ul>

      <a href="/blog.html" className="navbar-links">
        <button
          className="navbar-btn"
          type="button"
          disabled={isButtonDisabled}
          onClick={handleChatBtnClick}
        >
          <FontAwesomeIcon icon={faCommentDots} /> المدونة
        </button>
      </a>

      {/* Mobile */}
      <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
        <div onClick={openNav} className="mobile-navbar-close">
          <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
        </div>

        <ul className="mobile-navbar-links">
          <li>
            <Link onClick={openNav} to="/">
              الصفحة الرئيسية
            </Link>
          </li>
          <li>
            <a onClick={openNav} href="#services">
              خدماتنا
            </a>
          </li>
          <li>
            <a onClick={openNav} href="#about">
              من نحن
            </a>
          </li>
          <li>
            <a onClick={openNav} href="/blog.html">
              المدونة
            </a>
          </li>
        </ul>
      </div>

      {/* Hamburger Icon */}
      <div className="mobile-nav">
        <FontAwesomeIcon
          icon={faBars}
          onClick={openNav}
          className="hamb-icon"
        />
      </div>
    </div>
  );
}

export default Navbar;
