import React from "react";
import "../Styles/map.css";

function MapSection() {
  return (
    <div className="map-section">
      <h2 className="map-title">📍 موقعنا على الخريطة</h2>
      <div className="map-container">
        <iframe
          title="Google Maps"
          className="map-iframe"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13817.305454530006!2d31.33722908577881!3d30.07527067123347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583f410ab70a0b%3A0x775de8401716c92c!2sLetwal!5e0!3m2!1sen!2seg!4v1644798011893!5m2!1sen!2seg"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
}

export default MapSection;
